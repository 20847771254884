@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
@font-face {
  font-family: 'Darkline';
  src: url('./assets/fonts/Darkline.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

img {
  object-fit: cover;
}

@media screen and (min-width:1024px) {
  img {
    object-fit: contain;
  }
}


/* Fonts */
@font-face {
  font-family: 'Bebas';
  src: url('./assets/fonts/BebasNeue-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-weight: 100;
  font-style: normal;
  src: url('./assets/fonts/Lato/Lato-Thin.ttf') format('woff');
}
@font-face {
  font-family: 'Lato';
  font-weight: 300;
  font-style: normal;
  src: url('./assets/fonts/Lato/Lato-Light.ttf') format('woff');
}
@font-face {
  font-family: 'Lato';
  font-weight: 400;
  font-style: normal;
  src: url('./assets/fonts/Lato/Lato-Regular.ttf') format('woff');
}
@font-face {
  font-family: 'Lato';
  font-weight: 700;
  font-style: normal;
  src: url('./assets/fonts/Lato/Lato-Bold.ttf') format('woff');
}
@font-face {
  font-family: 'Lato';
  font-weight: 900;
  font-style: normal;
  src: url('./assets/fonts/Lato/Lato-Black.ttf') format('woff');
}
/* Custom Classes */
.Bebas {
  font-family: Bebas;
}
.Lato {
  font-family: Lato;
}
.bg-transparent {
  background-color: transparent;
}
/* swiper customize */
.swiper {
  width: 100%;
  height: 100vh;
}

.swiper-slide {
  color: white;
  text-align: center;
  font-size: 18px;
  background: #000;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* NavBar */
.menu-nav {
  background-image: url('./assets/images/Backgrounds/Menu.png');
  background-size: cover;
  background-repeat: no-repeat;
}

/* Home */
.first-section-home {
  background-image: url('./assets/images/desktopView/HelloBgDesktop.png');
  background-size: 100vw 100vh;
}
@media (max-width:767px) {
  .first-section-home {
    background-image: url('./assets/images/desktopView/homeMob.png');
    background-size: 100vw 100vh;
  } 
}
.second-section-home {
  background-image: url('./assets/images/desktopView/HomgBg2Desktop.png');
  background-size: 100vw 100vh;
}
@media (max-width:767px) {
  .second-section-home {
    background-image: url('./assets/images/desktopView/home2Mob.png');
    background-size: 100vw 100vh;
  }
}
.project-home {
  background-image: url('./assets/images/desktopView/Bg4.png');
  background-size: 100vw 100vh;
}
.third-section-home {
  background-image: url('./assets/images/desktopView/Bg4.png');
  background-size: 100vw 100vh;
}
.text-third-section {
  font-size: 80px;
}
@media(max-width:1170px) {
  .text-third-section {
    font-size: 70px;
  }
}
@media(max-width:1070px) {
  .text-third-section {
    font-size: 65px;
  }
}
@media(max-width:970px) {
  .text-third-section {
    font-size: 57px;
  }
}
@media(max-width:880px) {
  .text-third-section {
    font-size: 40px;
  }
}
.contact-us-section {
  background-image: url('./assets/images/desktopView/Bg4.png');
  background-size: 100vw 100%;
}
@media (max-width:767px) {
  .project-home {
    background-image: none;
  }
  .third-section-home {
    background-image: none;
  }
  .contact-us-section {
    background-size: 250% 100%;
  }
}
.Home-third-mob {
  background-image: url('./assets/images/desktopView/home3Mob.png');
  background-size: 100vw 100%;
}
.gallery::-webkit-scrollbar {
  height: 10px;
}
.gallery::-webkit-scrollbar-track {
  background-color: white;
}
.gallery::-webkit-scrollbar-thumb {
  background-color: #6155df ;
  border-radius: 10px;
}
.gallery-2::-webkit-scrollbar {
  height: 10px;
}
.gallery-2::-webkit-scrollbar-track {
  background-color: white;
}
.gallery-2::-webkit-scrollbar-thumb {
  background-color: #24dfe6 ;
  border-radius: 10px;
}
.scroll-yellow::-webkit-scrollbar {
  height: 10px;
}
.scroll-yellow::-webkit-scrollbar-track {
  background-color: #171617;
}
.scroll-yellow::-webkit-scrollbar-thumb {
  background-color: #c7da02 ;
  border-radius: 10px;
}
.scroll-purple::-webkit-scrollbar {
  height: 10px;
}
.scroll-purple::-webkit-scrollbar-track {
  background-color: #fff;
}
.scroll-purple::-webkit-scrollbar-thumb {
  background-color: #423c86 ;
  border-radius: 10px;
}
.scroll-blue::-webkit-scrollbar {
  height: 10px;
}
.scroll-blue::-webkit-scrollbar-track {
  background-color: #fff;
}
.scroll-blue::-webkit-scrollbar-thumb {
  background-color: #0a0ba3 ;
  border-radius: 10px;
}
.scroll-aqua::-webkit-scrollbar {
  height: 10px;
}
.scroll-aqua::-webkit-scrollbar-track {
  background-color: #fff;
}
.scroll-aqua::-webkit-scrollbar-thumb {
  background-color: #09a6ce ;
  border-radius: 10px;
}
:root {
  --scroll-color : "#cebd99"
}
.scroll-brown::-webkit-scrollbar {
  height: 10px;
}
.scroll-brown::-webkit-scrollbar-track {
  background-color: #fff;
}
.scroll-brown::-webkit-scrollbar-thumb {
  background-color: var(--scroll-color) ;
  border-radius: 10px;
}
a{
  text-decoration: none !important;
}
/* Animations */
.rotation-360 {
  animation-name: "";
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes rotation-360 {
  0%{
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.marquee-text-1 {
  animation-name: marquee-text-1;
  animation-duration: 40s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  transform: translateY(100%);
}
@keyframes marquee-text-1 {
  0%{
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-100%);
  }
}
.marquee-text-2 {
  transform: translateY(0%);
  animation-name: marquee-text-2;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-delay: 20s;
  animation-duration: 40s;
}
@keyframes marquee-text-2 {
  0%{
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-200%);
  }
}
.marquee-text-3 {
  position: absolute;
  animation-name: marquee-text-3;
  animation-duration: 25s;
  transform: translateY(150%);
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes marquee-text-3 {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-100%);
  }
}

.marquee-text-4 {
  animation-name: marquee-text-4;
  animation-duration: 25s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  transform: translateY(150%);
  animation-delay: 12500ms;
}

@keyframes marquee-text-4 {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-100%);
  }
}

@keyframes marquee-text-5 {
  0%{
    transform: translateY(-140%);
  }
  100% {
    transform: translateY(58%);
  }
}

@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
.ticker-wrap {
  position: absolute;
  top: 75px;
  width: 100%;
  overflow: hidden;
  height: 4rem;
  padding-left: 100%;
  box-sizing: content-box;
}
.ticker-wrap .ticker {
  display: inline-block;
  height: 4rem;
  line-height: 4rem;
  white-space: nowrap;
  padding-right: 100%;
  box-sizing: content-box;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: ticker;
  animation-name: ticker;
  -webkit-animation-duration: 30s;
  animation-duration: 30s;
}
.ticker-wrap .ticker__item {
  display: inline-block;
  padding: 0 10px;
  font-size: 35px;
  font-family: Bebas;
  color: #262626;
}
.active-nav-link img{
  display: block;
}
.h3-hero {
  font-size: 120px;
}
.we-are-hero {
  width: 400px;
}
.qoute-start , .qoute-end {
  width: 50px;
}
@media (max-width: 1950px) {
  .h3-hero {
    font-size: 190px;
    line-height: 150px;
    letter-spacing: 3px;
  }
  .we-are-hero {
    width: 700px;
  }
  .qoute-start , .qoute-end {
    width: 40px;
  }
  .rotation-360 {
    width: 400px;
  }
  .logo-nav {
    width: 170px;
  }
}
@media (max-width: 1750px) {
  .h3-hero {
    font-size: 180px;
    line-height: 150px;
    letter-spacing: 3px;
  }
  .we-are-hero {
    width: 600px;
  }
  .qoute-start , .qoute-end {
    width: 40px;
  }
  .rotation-360 {
    width: 380px;
  }
  .logo-nav {
    width: 170px;
  }
}
@media (max-width: 1650px) {
  .h3-hero {
    font-size: 150px;
    line-height: 125px;
    letter-spacing: 3px;
  }
  .we-are-hero {
    width: 480px;
  }
  .qoute-start , .qoute-end {
    width: 60px;
  }
  .rotation-360 {
    width: 350px;
  }
  .logo-nav {
    width: 170px;
  }
}
@media (max-width: 1600px) {
  .h3-hero {
    font-size: 125px;
    letter-spacing: 3px;
    line-height: 110px;
  }
  .we-are-hero {
    width: 370px;
  }
  .qoute-start , .qoute-end {
    width: 40px;
  }
  .logo-nav {
    width: 120px;
  }
  .rotation-360 {
    width: 270px;
  }
}
@media (max-width: 1100px) {
  .h3-hero {
    font-size: 100px;
    letter-spacing: 2px;
    line-height: 90px;
  }
  .we-are-hero {
    width: 350px;
  }
  .qoute-start , .qoute-end {
    width: 40px;
  }
}
@media (max-width: 950px) {
  .h3-hero {
    font-size: 90px;
    letter-spacing: 2px;
    line-height: 80px;
  }
  .we-are-hero {
    width: 300px;
  }
  .qoute-start , .qoute-end {
    width: 30px;
  }
}
@media (max-width: 870px) {
  .h3-hero {
    font-size: 80px;
    letter-spacing: 2px;
    line-height: 70px;
  }
  .we-are-hero {
    width: 280px;
  }
  .qoute-start , .qoute-end {
    width: 27px;
  }
  .qoute-end {
    transform: translateY(-50px) translateX(6px);
  }
}
@media (max-width: 720px) {
  .h3-hero {
    font-size: 70px;
    letter-spacing: 1px;
    line-height: 70px;
  }
  .we-are-hero {
    width: 260px;
  }
  .qoute-start , .qoute-end {
    width: 25px;
  }
  .qoute-end {
    transform: translateY(-50px) translateX(6px);
  }
}
@media (max-width: 600px) {
  .h3-hero {
    font-size: 60px;
    letter-spacing: 1px;
    line-height: 70px;
  }
  .we-are-hero {
    width: 260px;
  }
  .qoute-start , .qoute-end {
    width: 25px;
  }
  .qoute-end {
    transform: translateY(-50px) translateX(6px);
  }
  .rotation-360 {
    animation-name: rotation-360;
  }
}
@media (max-width: 550px) {
  .h3-hero {
    font-size: 50px;
    letter-spacing: 1px;
    line-height: 60px;
  }
  .we-are-hero {
    width: 230px;
  }
  .qoute-start , .qoute-end {
    width: 25px;
  }
  .qoute-end {
    transform: translateY(-40px) translateX(6px);
  }
}
@media (max-width: 470px) {
  .h3-hero {
    font-size: 46px;
    letter-spacing: 1px;
    line-height: 55px;
  }
  .we-are-hero {
    width: 230px;
  }
  .qoute-start , .qoute-end {
    width: 25px;
  }
  .qoute-end {
    transform: translateY(-40px) translateX(6px);
  }
}
@media (max-width: 440px) {
  .h3-hero {
    font-size: 40px;
    letter-spacing: 1px;
    line-height: 45px;
  }
  .we-are-hero {
    width: 210px;
  }
  .qoute-start , .qoute-end {
    width: 22px;
  }
  .qoute-end {
    transform: translateY(-24px) translateX(6px);
  }
}
@media (max-width: 380px) {
  .h3-hero {
    font-size: 36px;
    letter-spacing: 1px;
    line-height: 42px;
  }
  .we-are-hero {
    width: 180px;
  }
  .qoute-start , .qoute-end {
    width: 18px;
  }
  .qoute-end {
    transform: translateY(-24px) translateX(6px);
  }
}
@media (max-width : 1950px) {
  .text-third-section {
    font-size: 125px;
  }
  .p-section3 p{
    font-size: 19px;
    max-width: 1300px;
  }
}
@media (max-width : 1850px) {
  .text-third-section {
    font-size: 120px;
  }
  .p-section3 p{
    font-size: 19px;
    max-width: 1200px;
  }
}
@media (max-width : 1750px) {
  .text-third-section {
    font-size: 115px;
  }
  .p-section3 p{
    font-size: 19px;
    max-width: 1100px;
  }
}
@media (max-width : 1700px) {
  .text-third-section {
    font-size: 105px;
  }
  .p-section3 p{
    font-size: 18px;
    max-width: 900px;
  }
}
@media (max-width : 1550px) {
  .text-third-section {
    font-size: 95px;
  }
  .p-section3 p{
    font-size: 18px;
    max-width: 800px !important;
  }
}
@media (max-width : 1400px) {
  .text-third-section {
    font-size: 87px;
  }
  .p-section3 p{
    max-width: 710px;
  }
}
@media (max-width : 1250px) {
  .text-third-section {
    font-size: 80px;
  }
  .p-section3 p{
    max-width: 750px;
  }
}
@media (max-width : 1200px) {
  .text-third-section {
    font-size: 70px;
    line-height: 75px !important;
  }
  .p-section3 p{
    max-width: 570px;
  }
}
@media (max-width : 1050px) {
  .text-third-section {
    font-size: 62px;
    line-height: 65px !important;
  }
  .p-section3 p{
    max-width: 550px;
    font-size: 15px;
  }
}
@media (max-width : 930px) {
  .text-third-section {
    font-size: 56px;
    line-height: 65px !important;
  }
  .p-section3 p{
    max-width: 500px;
  }
}
@media (max-width : 850px) {
  .text-third-section {
    font-size: 52px;
    line-height: 55px !important;
  }
  .p-section3 p{
    max-width: 470px;
    font-size: 14px;
  }
}
@media (max-width : 7608px) {
  .p-section3 p{
    max-width: 100%;
    width: 100%;
  }
}
