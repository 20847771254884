.OverviewHeading {
  font-family: "Bebas";
}
.brandImage {
  width: 80px;
  height: 80px;
  margin-right: 8px;
}

.carouselContainer {
  overflow: hidden;
}

.imageCarousel,
.imageCarouselTwo {
  display: inline-block;
  line-height: 116.8%;
  white-space: nowrap;
}
.imageCarousel {
  animation: moveimages 25s linear infinite;
}

.imageCarouselTwo {
  animation: moveimagesTwo 25s linear infinite;
}

@media screen and (min-width: 1023px) {
  .imageCarousel,
  .imageCarouselTwo {
    display: flex;
    flex-direction: column;
  }
}

.imageCarousel span,
.imageCarouselTwo span {
  margin-right: 20px;
  font-family: Bebas;
}

@keyframes moveimages {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(calc(-250% - 20px));
  }
}

@media screen and (min-width: 1023px) {
  @keyframes moveimages {
    0% {
      transform: translateY(40%);
    }
    100% {
      transform: translateY(calc(-100% - 20px));
    }
  }
}

@media screen and (min-width: 1023px) {
  @keyframes moveimagesTwo {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(calc(50% + 20px));
    }
  }
}

@media screen and (max-width: 568px) {
  @keyframes moveimages {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(calc(-350% - 20px));
    }
  }
}
