.discoverCardImage {
    position: relative;
}
.discoverCardImage::before {
    content: "";
    width: 100%;
    height: 100%;
    background-image: url('../../assets/images/work/workBackground.png');
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
}

.discoverCardHeading {
    font-size: 18px;
    line-height: 19px;
    letter-spacing: 0.16px;
}

.discoverCardDescription {
    font-size: 13px;
    line-height: 8.7px;
}