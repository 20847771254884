@tailwind base;
@tailwind components;
@tailwind utilities;

.about {
  background-color: #131314;
  background-image: url("./../../assets/images/Backgrounds/backgroundMobiles.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.lato {
  font-family: 'lato';
}

.Bebas {
  font-family: 'Bebas';
}

.darkline {
  font-family: darkline;
}

/* .aboutHeading {
 font-size: 7rem;
} */

.aboutDesc {
  line-height: 133%;
  font-size: 0.8rem;
  margin-bottom: 90px;
}

.subHeading {
  font-size: 2.5rem;
  line-height: 123%;
  font-family: "Bebas";
  margin-bottom: 120px;
}

.sectionHeading {
  font-size: 4.5rem;
  color: #c7da02;
  margin-bottom: 30px;
}

.sectiondesc {
  line-height: 19px;
  font-size: 0.8125rem;
}

.discoverLink {
  font-family: "Bebas";
  font-size: 2.5rem;
  line-height: 100%;
}

@media screen and (min-width: 768px) {
  .aboutDesc {
    font-size: 1.3rem;
    line-height: 32px;
    margin-bottom: 257px;
  }

  .subHeading {
    font-size: 5rem;
    margin-bottom: 120px;
    line-height: 100%;
  }

  .sectionHeading {
    font-size: 5rem;
    color: #c7da02;
    margin-bottom: 40px;
  }

  .sectiondesc {
    line-height: 32px;
    font-size: 1.25rem;
    text-align: left;
  }

}

@media screen and (min-width: 1024px) {
  .plan {
    width: 40%;
  }

  .arrow {
    width: 15%;
  }
  
  .discoverLink {
    font-size: 7.5rem;
  }
}