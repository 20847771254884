@tailwind base;
@tailwind components;
@tailwind utilities;

.discover {
    background-color: #131314;
    background-image: url("./../../assets/images/Backgrounds/backgroundMobiles.png");
    background-size: cover;
    background-repeat: no-repeat;
}

.discoverHeading {
    font-family: "Bebas";
}

.emoji {
    width: 85px;
    height: 85px;
    margin-top: 10px;
    position: absolute;
    right: -60px;
    top: 10px;
}

@media screen and (min-width:470px) {
    .emoji {
        width: 108px;
        height: 108px;
        right: -100px;
        top: -15px;
    }
}


@media screen and (min-width:768px){
    .emoji {
        right: -170px;
        width: 190px;
        height: 190px;
    }
}
